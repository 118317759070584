/* External Dependencies */
import React from 'react';
import { Div, Text } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';
/* Local Dependencies */
/* Components */
import EnterCodeForm from './EnterCodeForm';
import SigninText from '../sign_in/SignInComponents/SigninText';
/* Style */
import '../sign_in/SignIn.scss';

export default function EnterCode() {
  const { t } = useI18next();
  const translater = {
    Registration: t('Registration'),
    p_Wooden_hands: t('p_Wooden_hands'),
  };
  return (
    <Div className="signin_window" data-testid="EnterCode_MainDiv_TestID">
      <Div className="signin_window_content">
        <Div>
          <Text
            tag="h1"
            m="60px auto 16px"
            style={{
              fontSize: '40px',
              fontWeight: '500',
              lineHeight: '38.6px',
            }}
          >
            {translater.Registration}
          </Text>
          <SigninText />
        </Div>
        <EnterCodeForm />
      </Div>
    </Div>
  );
}
