/* External Dependencies */
import React from 'react';
import { Div, Text } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useDispatch, useSelector } from 'react-redux';
/* Local Dependencies */
/* Components */
import BackAndCancelButtons from '../sign_in/SignInComponents/BackAndCancelButtons';
import NewPasswordForm from './PasswordResetComponents/NewPasswordForm';
import SigninText from '../sign_in/SignInComponents/SigninText';
import { PageStages } from '../../Main/WebApp';
import { setCurrentPage } from '../../Main/redux/currentPage_action';
import { codeChecking, setCode } from './redux/codecheckaction';
/* Style */
import '../sign_in/SignIn.scss';
import { RootState } from '../../../../redux/store';

export default function PasswordReset() {
  const { code, codeValidationPassed } = useSelector(
    (state: RootState) => state.code_check,
  );
  const dispatch = useDispatch();
  const onBackClick = () => {
    dispatch(setCode([]));
    dispatch(codeChecking());
    dispatch(setCurrentPage(PageStages.WRITE_CODE_PAGE));
  };
  const onCancelClick = () => {
    dispatch(setCurrentPage(PageStages.CONTENT_PAGE));
  };

  const { t } = useI18next();
  const translater = {
    NewPassword: t('NewPassword'),
    p_Wooden_hands: t('p_Wooden_hands'),
  };
  return (
    <Div data-testid="NewPasswordTestID" className="signin_window">
      <BackAndCancelButtons onBackClick={onBackClick} onCancelClick={onCancelClick} />
      <Div className="signin_window_content" data-testid="PasswordReset_NewPassword_TestID">
        <Div>
          <Text
            tag="h1"
            m="60px auto 16px"
            style={{
              fontSize: '40px',
              fontWeight: '500',
              lineHeight: '38.6px',
            }}
          >
            {translater.NewPassword}
          </Text>
          <SigninText />
        </Div>
        <NewPasswordForm />
      </Div>
    </Div>
  );
}
