export const SET_EMAIL = 'SET_EMAIL';
export const EMAIL_CHECKING = 'EMAIL_CHECKING';
export const SET_LOADING = 'SET_LOADING';

export const setEmail = (email) => ({
  type: SET_EMAIL,
  payload: email,
});

export const emailChecking = () => ({
    type: EMAIL_CHECKING,
  });
