export enum LoginActionTypes {
  SET_EMAIL = 'SET_EMAIL',
  SET_PASSWORD = 'SET_PASSWORD',
  SET_REPEAT_PASSWORD = 'SET_REPEAT_PASSWORD',
  SET_CODE = 'SET_CODE',
  EMAIL_CHECKING = 'EMAIL_CHECKING',
  PASSWORD_CHECKING = 'PASSWORD_CHECKING',
  CODE_CHECKING = 'CODE_CHECKING',
  SIGNUP = 'SIGNUP',
  SIGNUP_SUCCEEDED = 'SIGNUP_SUCCEEDED',
  SIGNUP_FAILED = 'SIGNUP_FAILED',
  CONFIRM_CODE_REQUEST = 'CONFIRM_CODE_REQUEST',
  CONFIRM_CODE_SUCCESS = 'CONFIRM_CODE_SUCCESS',
  CONFIRM_CODE_FAILURE = 'CONFIRM_CODE_FAILURE',
}

export const setEmail = (email) => ({
  type: LoginActionTypes.SET_EMAIL,
  payload: email,
});

export const emailChecking = () => ({
  type: LoginActionTypes.EMAIL_CHECKING,
});

export const setPassword = (password) => ({
  type: LoginActionTypes.SET_PASSWORD,
  payload: password,
});

export const setRepeatPassword = (repeatPassword) => ({
    type: LoginActionTypes.SET_REPEAT_PASSWORD,
    payload: repeatPassword,
});

export const passwordChecking = () => ({
    type: LoginActionTypes.PASSWORD_CHECKING,
});

export const setCode = (code) => ({
  type: LoginActionTypes.SET_CODE,
  payload: code,
});

export const codeChecking = () => ({
  type: LoginActionTypes.CODE_CHECKING,
});

export interface LoginAction {
  type: LoginActionTypes;
  payload?: any;
}

export const signUp = (email: string, password: string) => ({
  type: LoginActionTypes.SIGNUP,
  payload: { email, password },
});

export const signUpSucceeded = (): LoginAction => ({
  type: LoginActionTypes.SIGNUP_SUCCEEDED,
});

export const signUpFailed = (): LoginAction => ({
  type: LoginActionTypes.SIGNUP_FAILED,
});

export const confirmCodeRequest = (email, code) => ({
  type: LoginActionTypes.CONFIRM_CODE_REQUEST,
  payload: { email, code },
});

export const confirmCodeSuccess = (result) => ({
  type: LoginActionTypes.CONFIRM_CODE_SUCCESS,
  payload: { result },
});

export const confirmCodeFailure = (error) => ({
  type: LoginActionTypes.CONFIRM_CODE_FAILURE,
  payload: { error },
});
