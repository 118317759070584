/* External dependencies */
import { applyMiddleware, combineReducers, createStore as createReduxStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
/* Local dependencies */
import codecheckReducer from '../components/webapp/Login/password_reset/redux/codecheckreducer';
import currentPageReducer from '../components/webapp/Main/redux/currentPage_reducer';
import emailcheckReducer from '../components/webapp/Login/password_reset/redux/emailcheckreducer';
import epics from '../components/webapp/Login/password_reset/redux/epics';
import signInOutEpics from '../components/webapp/Login/sign_in/redux/epics';
import forgotPasswordReducer from '../components/webapp/Login/password_reset/redux/forgotPasswordReducer';
import getDevices from '../components/devices/getDevices/redux/reducer';
import loginReducer from '../components/webapp/Login/sign_in/redux/loginreducer';
import passwordcheckReducer from '../components/webapp/Login/password_reset/redux/passwordcheckreducer';
import signInEpic from '../components/webapp/Login/sign_in/redux/epics';
import { signUpRootReducer } from '../components/webapp/Login/registration/redux/reducer';
import { signUpEpic, signUpConfirmCodeEpic } from '../components/webapp/Login/registration/redux/epics';

const rootEpic = combineEpics(
  epics.forgotPasswordEpic,
  epics.confirmPasswordEpic,
  signUpEpic,
  signUpConfirmCodeEpic,
  signInOutEpics.signInEpic,
  signInOutEpics.signOutEpic,
);

const rootReducer = combineReducers({
  getDevices,
  login: loginReducer,
  password_reset: emailcheckReducer,
  forgotPassword: forgotPasswordReducer,
  code_check: codecheckReducer,
  new_password: passwordcheckReducer,
  current_page: currentPageReducer,
  signup: signUpRootReducer,
});

let store;

export function createStore(): Store {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
