/* External dependencies */
import { useI18next } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';
import { Div, Button, Text, Image } from 'atomize';
import { Link } from 'gatsby';
import { RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
/* Local dependencies */
// Images
import account_circle from '../../../assets/images/account_circle.svg';
import cup from '../../../assets/images/cup.svg';
import expand_more from '../../../assets/images/expand_more.svg';
/* Components */
import FinikMapButton from './header_components/FinikMapButton';
import SignInSmallButton from './header_components/SignInSmallButton';
import SearchButton from './header_components/SearchButton';
import { PageStages } from './WebApp';
import { setCurrentPage } from './redux/currentPage_action';
import { signOut } from '../Login/sign_in/redux/authaction';
/* Styles */
import '../Login/sign_in/SignIn.scss';
import GooglePlayAndAppStoreButtons from './header_components/GooglePlayAndAppStoreButtons';

export default function Header() {
  const email = useSelector((state: RootState) => state.login.email);
  const { isAuthenticated } = useSelector((state: RootState) => state.login);

  const dispatch = useDispatch();
  const onSignInClick = () => {
    dispatch(setCurrentPage(PageStages.WELCOME_PAGE));
  };
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleExpandClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const { t } = useI18next();
  const Sign_in = t('Sign in');
  const Download_app = t('Download the app');
  const SignOut = t('Sign out');

  return (
    <Div
      data-testid="header"
      h="auto"
      w="100%"
      mx="auto"
      m={{ b: '20px' }}
      p="0"
      d="inline-flex"
      justify="space-between"
    >
      {!isAuthenticated && <FinikMapButton />}
      {isAuthenticated && (
        <Div w="457px" d="flex" justify="space-between">
          <FinikMapButton />
          <SearchButton />
        </Div>
      )}
      {!isAuthenticated && (
        <Div w="920px" h="46px" gap="10px" d="flex" justify="space-between">
          <Button
            onClick={onSignInClick}
            data-testid="sign_in_big_button"
            w="303px"
            h="40px"
            m={{ t: '4px' }}
            p="10px"
            border="1px solid"
            bordered="10px"
            style={{
              backgroundColor: '#ACF709',
              color: '#000000',
              fontSize: '14px',
              fontWeight: '700',
              lineheight: '19.6px',
            }}
          >
            {Sign_in}
          </Button>
          <Button
            data-testid="download-app-button-webapp"
            w="303px"
            h="40px"
            m={{ t: '4px' }}
            p="10px"
            border="1px solid"
            bordered="10px"
            borderColor="#FFFFFF"
            bg="linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, rgba(10, 10, 10, 0.7) 100%), linear-gradient(0deg, #454545, #454545)"
            style={{
              color: '#FFFFFF',
              fontSize: '14px',
              fontWeight: '700',
              lineheight: '19.6px',
            }}
          >
            {Download_app}
          </Button>
          <Div
            pos="relative"
            w="298px"
            h="46px"
            d="flex"
            rounded="10px"
            border="1px solid"
            borderColor="#454545"
            flexDir="inline"
            justify="space-between"
            alignitems="center"
            bg="linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, rgba(10, 10, 10, 0.7) 100%), linear-gradient(0deg, #454545, #454545)"
          >
            <SignInSmallButton />
            <GooglePlayAndAppStoreButtons />
          </Div>
        </Div>
      )}
      {isAuthenticated && (
        <Div w="584px" h="46px" gap="10px" d="flex" justify="space-between">
          <Link to="#">
            <Button
              data-testid="points-webapp-header"
              w="144px"
              h="46px"
              p="0"
              rounded="10px"
              border="1px solid"
              borderColor="#454545"
              bg={`linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, rgba(10, 10, 10, 0.7) 100%), linear-gradient(0deg, #454545, #454545)`}
            >
              <Div
                w="104px"
                h="20px"
                m="13px 20px"
                gap="4px"
                d="flex"
                justify="space-between"
                style={{
                  color: '#D9D9D9',
                  fontSize: '14px',
                  fontWeight: '500',
                  lineheight: '16.94px',
                  textAlign: 'right',
                }}
              >
                <Image src={cup} alt="cup" w="20px" h="20px" />
                <Text
                  style={{
                    color: '#FFFFFF',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineheight: '16.94px',
                    textAlign: 'right',
                  }}
                >
                  320
                </Text>
                <Text>баллов</Text>
              </Div>
            </Button>
          </Link>
          <Div
            pos="relative"
            w="420px"
            h="46px"
            d="flex"
            rounded="10px"
            border="1px solid"
            borderColor="#454545"
            flexDir="inline"
            justify="space-between"
            alignitems="center"
            bg={`linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, rgba(10, 10, 10, 0.7) 100%), linear-gradient(0deg, #454545, #454545)`}
          >
            <Div
              w="192px"
              h="20px"
              m="auto 0 auto 20px"
              d="flex"
              style={{
                fontSize: '14px',
                lineHeight: '16.94px',
                fontWeight: '500',
                color: '#FFFFFF',
              }}
            >
              <Image src={account_circle} alt="account_circle" w="20px" h="20px" />
              <Text
                data-testid="email-webapp-header"
                w="136px"
                m="auto 8px"
                style={{
                  fontSize: '14px',
                  lineHeight: '16.94px',
                  fontWeight: '500',
                  color: '#FFFFFF',
                  textAlign: 'center',
                  wordWrap: 'break-word',
                }}
              >
                {email}
              </Text>
              <Div>
              <Image
                  src={expand_more}
                  alt="expand_more"
                  w="20px"
                  h="20px"
                  style={{ cursor: 'pointer' }}
                  onClick={handleExpandClick}
                />
              {isMenuOpen && (
                <Div
                  position="absolute"
                  top="30px"
                  right="0"
                  w="65px"
                  m="12px 0 0 -20px"
                  shadow="md"
                  p="2"
                  rounded="md"
                  bg={`linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, rgba(10, 10, 10, 0.7) 100%), linear-gradient(0deg, #454545, #454545)`}
                  border="1px solid"
                  borderColor="#454545"
                >
                  <Div
                    tag="button"
                    onClick={handleSignOut}
                    w="100%"
                    textAlign="left"
                    p="2"
                    hoverBg={`linear-gradient(260deg, rgba(0, 0, 0, 0.7) 0%, rgba(30, 30, 30, 0.9) 100%), linear-gradient(3deg, #454555, #554545)`}
                  >
                    {SignOut}
                  </Div>
                </Div>
                )}
              </Div>
            </Div>
            <GooglePlayAndAppStoreButtons />
          </Div>
        </Div>
      )}
    </Div>
  );
}
