/* External Dependencies */
import React from 'react';
import { Div, Image } from 'atomize';

/* Local Dependencies */
import { BackToSignInOrRegistrWindowProps, CancelInSigninProps } from '../../../OnClick_Props';
/* Images */
import cancel_black_24dp from '../../../../../assets/images/cancel_black_24dp.svg';
import Subtract from '../../../../../assets/images/Subtract.svg';

export default function BackAndCancelButtons({
  onBackClick,
  onCancelClick,
}: BackToSignInOrRegistrWindowProps & CancelInSigninProps) {
  return (
    <Div data-testid="BackAndCancelButtons" w="100%" h="50px" d="flex" justify="space-between">
      <Image
        src={Subtract}
        alt="Back"
        onClick={onBackClick}
        data-testid="back-button-in-signin-window"
        w="32px"
        h="32px"
        m="20px auto auto 20px"
      />
      <Image
        src={cancel_black_24dp}
        alt="cancel"
        onClick={onCancelClick}
        data-testid="cancel-button-in-signin-window"
        w="36px"
        h="36px"
        m="20px 20px auto auto"
      />
    </Div>
  );
}
