/* Global dependencies */
import React, { useState, useEffect } from 'react';
import { Div, Button, Text, Input } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useSelector, useDispatch } from 'react-redux';
/* Local dependencies */
import Checking from '../sign_in/SignInComponents/Checking';
import { PageStages } from '../../Main/WebApp';
import { RootState } from '../../../../redux/store';
import { setCurrentPage } from '../../Main/redux/currentPage_action';
import { setPassword, passwordChecking, setRepeatPassword } from '../password_reset/redux/passwordcheckaction';
import { signUp } from './redux/actions';
/* Style */
import '../sign_in/SignIn.scss';

const EnterPasswordForm = () => {
  const { t } = useI18next();
  const translater = {
    PasswordResetInputEmail: t('PasswordResetInputEmail'),
    Checking: t('Checking'),
    EnterPassword: t('EnterPassword'),
    ICanNotRememberThePassword: t('ICanNotRememberThePassword'),
    RepeatPassword: t('RepeatPassword'),
    Next: t('Next'),
  };

  const [isLoading, setIsLoading] = useState(false);
  const { email, password, passwordError, repeatPassword, repeatPasswordError, passwordValidationPassed } = useSelector(
    (state: RootState) => state.signup.emailPassword,
  );

  const dispatch = useDispatch();

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPassword(e.target.value));
  };

  const handleRepeatPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setRepeatPassword(e.target.value));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(passwordChecking());
  };

  const signUpGetValues = () => {
    dispatch(signUp(email, password));
  };

  const onNextClick = () => {
    dispatch(setCurrentPage(PageStages.REGISTRATION_CODE_PAGE));
  };
  useEffect(() => {
    if (passwordValidationPassed) {
      signUpGetValues();
      setIsLoading(true);
      onNextClick();
    }
  }, [passwordValidationPassed]);

  return (
    <form onSubmit={handleSubmit} data-testid="EnterPasswordForm_TestID">
      {isLoading ? (
        <Checking />
      ) : (
        <Div data-testid="MainDivTestID" w="343px" h="320px" d="flex" flexDir="column" justify="space-between">
          <Div>
            <Text
              m="5px auto 16px auto"
              textAlign="left"
              style={{
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '16.94px',
              }}
            >
              {translater.EnterPassword}
            </Text>
            <Input
              w="343px"
              h="52px"
              border="1.5px solid"
              borderColor="#3A3A3A"
              bordered="10px"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              style={{
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '18.2px',
                color: '#FFFFFF',
                background: '#222222',
              }}
              placeholder="********"
            />
            {passwordError && (
              <Div
                m={{ b: '-20px' }}
                textAlign="right"
                style={{
                  color: 'red',
                  fontSize: '10px',
                }}
              >
                {t(passwordError)}
              </Div>
            )}
          </Div>
          <Div>
            <Text
              m="-5px auto 16px auto"
              textAlign="left"
              style={{
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '16.94px',
              }}
            >
              {translater.RepeatPassword}
            </Text>
            <Input
              w="343px"
              h="52px"
              border="1.5px solid"
              borderColor="#3A3A3A"
              bordered="10px"
              type="password"
              value={repeatPassword}
              onChange={handleRepeatPasswordChange}
              style={{
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '18.2px',
                color: '#FFFFFF',
                background: '#222222',
              }}
              placeholder="********"
            />
            {repeatPasswordError && (
              <Div
                m={{ b: '-20px' }}
                textAlign="right"
                style={{
                  color: 'red',
                  fontSize: '10px',
                }}
              >
                {t(repeatPasswordError)}
              </Div>
            )}
          </Div>
          <Button
            onClick={handleSubmit}
            data-testid="NextButton"
            w="344px"
            h="52px"
            bordered="10px"
            m={{ b: '32px' }}
            p="10px"
            gap="10px"
            bg="#ACF709"
            type="submit"
            style={{
              fontWeight: '700',
              fontSize: '16px',
              lineHeight: '22.4px',
              color: '#000000',
            }}
          >
            {translater.Next}
          </Button>
        </Div>
      )}
    </form>
  );
};

export default EnterPasswordForm;
