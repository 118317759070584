/* External Dependencies */
import React from 'react';
import { Div } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useDispatch } from 'react-redux';
/* Local Dependencies */
/* Components */
import BackAndCancelButtons from '../sign_in/SignInComponents/BackAndCancelButtons';
import EnterEmailForm from './EnterEmailForm';
import H_Registration from './H_Registration';
import SigninText from '../sign_in/SignInComponents/SigninText';
import { PageStages } from '../../Main/WebApp';
import { setCurrentPage } from '../../Main/redux/currentPage_action';

/* Style */
import '../sign_in/SignIn.scss';

export default function RegistrationWindow() {
  const dispatch = useDispatch();
  const onBackClick = () => {
    dispatch(setCurrentPage(PageStages.WELCOME_PAGE));
  };
  const onCancelClick = () => {
    dispatch(setCurrentPage(PageStages.CONTENT_PAGE));
  };

  const { t } = useI18next();
  const translater = {
    p_Wooden_hands: t('p_Wooden_hands'),
    Registration: t('Registration'),
  };
  return (
    <Div data-testid="RegistrationWindowMainDiv" className="signin_window">
      <BackAndCancelButtons onBackClick={onBackClick} onCancelClick={onCancelClick} />
      <Div className="signin_window_content">
        <Div h="165px">
          <H_Registration />
          <SigninText />
        </Div>
        <EnterEmailForm />
      </Div>
    </Div>
  );
}
