// /* External Dependencies */
import React from 'react';
import { Text } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';

export default function H_Registration() {
  const { t } = useI18next();
  const translater = {
    Registration: t('Registration'),
  };

  return (
    <Text
      data-testid="H_RegistrationTestID"
      tag="h1"
      m="60px auto 16px"
      style={{
        fontSize: '40px',
        fontWeight: '500',
        lineHeight: '38.6px',
      }}
    >
      {translater.Registration}
    </Text>
  );
}
