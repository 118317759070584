import { combineReducers } from 'redux';
import { LoginActionTypes, LoginAction } from './actions';
import { validateEmail, validatePassword, validateCode } from '../../helper';

const initialState = {
  signingUp: false,
  signUpError: null,
  email: '',
  emailError: '',
  emailValidationPassed: false,
  password: '',
  passwordError: '',
  repeatPassword: '',
  repeatPasswordError: '',
  passwordValidationPassed: false,
  code: [],
  codeError: '',
  codeValidationPassed: false,
  confirm: false,
  result: null,
  error: null,
};

const signupEmailPasswordReducer = (state = initialState, action: LoginAction): any => {
  switch (action.type) {
    case LoginActionTypes.SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };

    case LoginActionTypes.EMAIL_CHECKING:
      return validateEmail(state);

    case LoginActionTypes.SET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };

    case LoginActionTypes.SET_REPEAT_PASSWORD:
      return {
        ...state,
        repeatPassword: action.payload,
      };

    case LoginActionTypes.PASSWORD_CHECKING:
      return validatePassword(state);

    case LoginActionTypes.SIGNUP:
      return {
        ...state,
        signingUp: true,
      };

    case LoginActionTypes.SIGNUP_SUCCEEDED:
      return {
        ...state,
        signingUp: true,
        signUpError: null,
      };

    case LoginActionTypes.SIGNUP_FAILED:
      return {
        ...state,
        signingUp: false,
        signUpError: 'RegistrationError',
      };
    default:
      return state;
  }
};

const signupConfirmCodeReducer = (state = initialState, action): any => {
  switch (action.type) {
    case LoginActionTypes.SET_CODE:
      return {
        ...state,
        code: action.payload,
      };
      
    case LoginActionTypes.CODE_CHECKING:
      return validateCode(state.code, state);
      
    case LoginActionTypes.CONFIRM_CODE_REQUEST:
      return { ...state, confirm: true, error: null };

    case LoginActionTypes.CONFIRM_CODE_SUCCESS:
      return { ...state, confirm: false, result: action.payload.result };

    case LoginActionTypes.CONFIRM_CODE_FAILURE:
      return { ...state, confirm: false, error: action.payload.error };

    default:
      return state;
  }
};
export const signUpRootReducer = combineReducers({
  emailPassword: signupEmailPasswordReducer,
  confirmCode: signupConfirmCodeReducer,
});
