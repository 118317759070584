/* Global dependencies */
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import CognitoClient from '@mancho.devs/cognito';

/* Local dependencies */
import { 
  SEND_CODE_REQUEST, 
  sendCodeSuccess, 
  sendCodeFailure,
  CONFIRM_PASSWORD_REQUEST,
  confirmPasswordFailure,
  confirmPasswordSuccess,
 } from './forgotPasswordAction';

const cognitoClient = new CognitoClient({
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
});

const forgotPasswordEpic = (action$) =>
  action$.pipe(
    ofType(SEND_CODE_REQUEST),
    mergeMap(({ payload: { email } }) =>
      from(cognitoClient.forgotPassword(email)).pipe(
        mergeMap((response) => of(sendCodeSuccess(response))),
        catchError((error) => of(sendCodeFailure(error))),
      ),
    ),
  );
  
const confirmPasswordEpic = (action$) =>
  action$.pipe(
    ofType(CONFIRM_PASSWORD_REQUEST),
    mergeMap(({ payload: { email, code, password } }) =>
      from(cognitoClient.confirmPassword(email, code, password)).pipe(
        mergeMap((response) => of(confirmPasswordSuccess(response))),
        catchError((error) => of(confirmPasswordFailure(error))),
      ),
    ),
  );

export default { forgotPasswordEpic, confirmPasswordEpic };
