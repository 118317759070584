/* Global dependencies */ import React from 'react';
import { Div } from 'atomize';
import { useSelector } from 'react-redux';
/* Local dependencies */
import Content from './Content';
import EnterCode from '../Login/registration/EnterCode';
import NewPassword from '../Login/password_reset/NewPassword';
import PasswordReset from '../Login/password_reset/PasswordReset';
import RegistrationWindow from '../Login/registration/RegistrationWindow';
import EnterPassword from '../Login/registration/EnterPassword';
import SigninOrRegistrWindow from '../Login/sign_in/SigninOrRegistrWindow';
import SigninWindow from '../Login/sign_in/SigninWindow';
import WriteCode from '../Login/password_reset/WriteCode';
import { PageStages } from './WebApp';
/* Style */
import './WebApp.scss';

const Main = () => {
  const currentPage = useSelector((state: any) => state.current_page.currentPage);

  return (
    <Div data-testid="main-component" w="100%" d="flex">
      {currentPage === PageStages.CONTENT_PAGE && <Content />}
      {currentPage === PageStages.WELCOME_PAGE && <SigninOrRegistrWindow />}
      {currentPage === PageStages.SIGNIN_PAGE && <SigninWindow />}
      {currentPage === PageStages.REGISTRATION_PAGE && <RegistrationWindow />}
      {currentPage === PageStages.REGISTRATION_PASSWORD_PAGE && <EnterPassword />}
      {currentPage === PageStages.REGISTRATION_CODE_PAGE && <EnterCode />}
      {currentPage === PageStages.PASSWORD_RESET_PAGE && <PasswordReset />}
      {currentPage === PageStages.WRITE_CODE_PAGE && <WriteCode />}
      {currentPage === PageStages.NEW_PASWORD_PAGE && <NewPassword />}
    </Div>
  );
};

export default Main;
