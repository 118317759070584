/* External dependencies */
import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Text } from 'atomize';

export default function SigninText() {
    const { t } = useI18next();
    const p_Wooden_hands = t('p_Wooden_hands');

    return (
        <Text
        data-testid="p_Wooden_hands"
        h="50px"
        style={{
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '19.6px',
        color: 'rgba(255, 255, 255, 0.8)',
        }}
        >
        {p_Wooden_hands}
        </Text>
    )
}