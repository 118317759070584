import React from 'react';
import { Div, Button, Text, Image } from 'atomize';

/* Local dependencies */
import apple_icon from '../../../../assets/images/apple_icon.svg';
import google_play_icon from '../../../../assets/images/google_play_icon_13.svg';


export default function GooglePlayAndAppStoreButtons() {
  return (
    <Div data-testid="google_play_and_app_store_buttons" className="google_play_and_app_store_buttons" w="208px" d="flex" justify="space-around" p="auto 0px">
      <Button
        w="80px"
        h="24px"
        m="auto 4px auto 20px"
        p={{ l: '5.9px' }}
        rounded="5px"
        border="0.16px solid"
        borderColor="#A6A6A6"
        textColor="white"
        d="flex"
      >
        <Image src={google_play_icon} alt="google_play_icon" w="13.68px" h="15.43px" />
        <Div w="67px" h="24px" p={{ l: '3px' }} d="flex" flexDir="column">
          <Text h="10px" textAlign="left" m={{ t: '-4px' }} tag="span">
            Get it on
          </Text>
          <Text tag="h3" h="14px" m={{ t: '-1px' }} textAlign="start">
            Google Play
          </Text>
        </Div>
      </Button>
      <Button
        w="80px"
        h="24px"
        m="auto 20px auto 4px"
        p={{ l: '5.9px' }}
        rounded="5px"
        border="0.16px solid"
        borderColor="#A6A6A6"
        textColor="white"
        d="flex"
      >
        <Image src={apple_icon} alt="apple_icon" w="12.3px" h="15.31px"></Image>
        <Div w="67px" h="24px" p={{ l: '3px' }} d="grid">
          <Text fontWeight="700" h="10px" textAlign="start" m={{ t: '-4px' }} tag="span">
            Download on the
          </Text>
          <Text tag="h3" fontSize="8px" textAlign="start" m={{ t: '-1px' }}>
            App Store
          </Text>
        </Div>
      </Button>
    </Div>
  );
}
