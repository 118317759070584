export const SEND_CODE_REQUEST = 'SEND_CODE_REQUEST';
export const SEND_CODE_SUCCESS = 'SEND_CODE_SUCCESS';
export const SEND_CODE_FAILURE = 'SEND_CODE_FAILURE';
export const CONFIRM_PASSWORD_REQUEST = 'CONFIRM_PASSWORD_REQUEST';
export const CONFIRM_PASSWORD_SUCCESS = 'CONFIRM_PASSWORD_SUCCESS';
export const CONFIRM_PASSWORD_FAILURE = 'CONFIRM_PASSWORD_FAILURE';

export const sendCodeRequest = (email: string) => ({
  type: SEND_CODE_REQUEST,
  payload: { email },
});

export const sendCodeSuccess = (response) => ({
  type: SEND_CODE_SUCCESS,
  payload: response,
});

export const sendCodeFailure = (error) => ({
  type: SEND_CODE_FAILURE,
  payload: error,
});

export const confirmPasswordRequest = (email: string, code: string, password: string) => ({
  type: CONFIRM_PASSWORD_REQUEST,
  payload: { email, code, password },
});

export const confirmPasswordSuccess = (response) => ({
  type: CONFIRM_PASSWORD_SUCCESS,
  payload: response,
});

export const confirmPasswordFailure = (error) => ({
  type: CONFIRM_PASSWORD_FAILURE,
  payload: error,
});
