/* Global dependencies */
import React from 'react';
import { Container, Div } from 'atomize';
/* Local dependencies */
import Header from './Header';
import Main from './Main';
import './WebApp.scss';

export enum PageStages {
  CONTENT_PAGE = 'CONTENT_PAGE',
  WELCOME_PAGE = 'WELCOME_PAGE',
  SIGNIN_PAGE = 'SIGNIN_PAGE',
  PASSWORD_RESET_PAGE = 'PASSWORD_RESET_PAGE',
  WRITE_CODE_PAGE = 'WRITE_CODE_PAGE',
  NEW_PASWORD_PAGE = 'NEW_PASSWORD_PAGE',
  REGISTRATION_PAGE = 'REGISTRATION_PAGE',
  REGISTRATION_PASSWORD_PAGE = 'REGISTRATION_PASSWORD_PAGE',
  REGISTRATION_CODE_PAGE = 'REGISTRATION_CODE_PAGE',
}

export default function WebApp() {
  return (
    <Div w="100%" h="100vh" p="20px 20px" m="0">
      <Container data-testid="container" p="0" maxW="100%" mx="auto">
        <Header />
        <Main />
      </Container>
    </Div>
  );
}
