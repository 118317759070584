export const SET_EMAIL = 'SET_EMAIL';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_LOADING = 'SET_LOADING';
export const AUTHORIZE_USER = 'AUTHORIZE_USER';
export const SIGNIN = 'SIGNIN';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_FAILURE = 'SIGNOUT_FAILURE';

export const setEmail = (email) => ({
  type: SET_EMAIL,
  payload: email,
});

export const setPassword = (password) => ({
  type: SET_PASSWORD,
  payload: password,
});

export const authorizeUser = () => ({
  type: AUTHORIZE_USER,
});

export const signIn = (email: string, password: string) => ({
  type: SIGNIN,
  payload: { email, password },
});

export const signInSuccess = (response) => ({
  type: SIGNIN_SUCCESS,
  payload: response,
});

export const signInFailure = (error) => ({
  type: SIGNIN_FAILURE,
  payload: error,
});

export const signOut = () => ({
  type: SIGNOUT,
});

export const signOutSuccess = () => ({
  type: SIGNOUT_SUCCESS,
});

export const signOutFailure = (error) => ({
  type: SIGNOUT_FAILURE,
  payload: error,
  error: true,
});
