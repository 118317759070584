/* Global dependencies */
import React from 'react';
import { Div, Button, Text, Image } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useDispatch } from 'react-redux';
/* Local dependencies */
import { PageStages } from '../WebApp';
import { setCurrentPage } from '../redux/currentPage_action';
import account_circle from '../../../../assets/images/account_circle.svg';

export default function SignInSmallButton() {
  const dispatch = useDispatch();
  const onSignInClick = () => {
    dispatch(setCurrentPage(PageStages.WELCOME_PAGE));
  };

  const { t } = useI18next();
  const Sign_in = t('Sign in');

  return (
    <Button
      onClick={onSignInClick}
      data-testid="sign_in_small_button"
      w="75px"
      h="20px"
      m="auto 0 auto 20px"
      p="0"
      textColor="white"
      d="flex"
      rounded="0"
      bg="none"
    >
      <Image src={account_circle} alt="account_circle" w="18px" h="18px" />
      <Div className="header_login" data-testid="header_login_text" w="70px" h="20px" p={{ l: '3px' }} textAlign="left">
        <Text
          tag="span"
          style={{
            fontSize: '14px',
            fontWeight: '500',
            lineheight: '16.94px',
            textUnderlineOffset: '3px',
          }}
          letterSpacing="0px"
          textDecor="underline"
          textdecorcolor="#FFFFFF"
        >
          {Sign_in}
        </Text>
      </Div>
    </Button>
  );
}
