import { CODE_CHECKING, SET_CODE } from './codecheckaction';
import { validateCode } from '../../helper';

const initialState = {
  code: [],
  codeError: '',
  codeValidationPassed: false,
  isLoading: false,
};

const codecheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CODE:
      return {
        ...state,
        code: action.payload,
      };

    case CODE_CHECKING:
      return validateCode(state.code, state)
      
    default:
      return state;
  }
};
export default codecheckReducer;
