/* Global dependencies */
import React, { useState, useEffect } from 'react';
import { Div, Text, Input } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useSelector, useDispatch } from 'react-redux';
/* Local dependencies */
import Checking from '../../sign_in/SignInComponents/Checking';
import NextButton from '../../sign_in/SignInComponents/NextButton';
import { PageStages } from '../../../Main/WebApp';
import { sendCodeRequest } from '../redux/forgotPasswordAction';
import { setCurrentPage } from '../../../Main/redux/currentPage_action';
import { setEmail, emailChecking } from '../redux/emailcheckaction';
import { RootState } from '../../../../../redux/store';
/* Images */
import mail from '../../../../../assets/images/mail.png';
/* Style */
import '../../sign_in/SignIn.scss';

const SendCodeForm = () => {
  const { t } = useI18next();
  const translater = {
    PasswordResetInputEmail: t('PasswordResetInputEmail'),
    Checking: t('Checking'),
    EnterYourEmail: t('EnterYourEmail'),
    EnterPassword: t('EnterPassword'),
    ICanNotRememberThePassword: t('ICanNotRememberThePassword'),
    GetCodeToMail: t('GetCodeToMail'),
  };

  const [isLoading, setIsLoading] = useState(false);
  const { email, emailError, emailValidationPassed } = useSelector((state: RootState) => state.password_reset);
  const { response, errorMessage } = useSelector((state: RootState) => state.forgotPassword);
  const dispatch = useDispatch();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEmail(e.target.value));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
      sendEmailToCognito();
      dispatch(emailChecking());
  };

  const onGetCodeToMailClick = () => {
    dispatch(setCurrentPage(PageStages.WRITE_CODE_PAGE));
  };

  const sendEmailToCognito = () => {
    dispatch(sendCodeRequest(email));
  };

  useEffect(() => {
    if (emailValidationPassed && response !== null) {
      setIsLoading(true);
      onGetCodeToMailClick();
    }
  }, [emailValidationPassed, response]);

  return (
    <form onSubmit={handleSubmit} data-testid="LoginFormTestIDForm">
      {isLoading ? (
        <Checking />
      ) : (
        <Div
          data-testid="PasswordReset_SendCodeForm_TestID"
          w="343px"
          h="265px"
          d="flex"
          flexDir="column"
          justify="space-between"
        >
          <Div>
            <Text
              m="16px auto"
              textAlign="left"
              style={{
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '16.94px',
              }}
            >
              {translater.PasswordResetInputEmail}
            </Text>
            <Div d="flex">
              <img
                src={mail}
                alt="#"
                style={{
                  position: 'absolute',
                  left: '4%',
                  top: '55.5%',
                  zIndex: '1',
                }}
              />
              <Input
                w="343px"
                h="52px"
                p={{ l: '45px' }}
                border="1.5px solid"
                borderColor="#3A3A3A"
                bordered="10px"
                bg="#222222"
                type="email"
                value={email}
                onChange={handleEmailChange}
                style={{
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '18.2px',
                  color: '#FFFFFF',
                }}
                placeholder={translater.EnterYourEmail}
              />
            </Div>
            {emailError && (
              <Div
                m={{ b: '-20px' }}
                textAlign="right"
                style={{
                  color: 'red',
                  fontSize: '10px',
                }}
              >
                {t(emailError)}
              </Div>
            )}
            {errorMessage && (
              <Div
                m={{ b: '-20px' }}
                textAlign="right"
                style={{
                  color: 'red',
                  fontSize: '10px',
                }}
              >
                {t(errorMessage)}
              </Div>
            )}
          </Div>
          <NextButton onClick={handleSubmit}>{translater.GetCodeToMail}</NextButton>
        </Div>
      )}
    </form>
  );
};

export default SendCodeForm;
