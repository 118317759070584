import { SET_EMAIL, EMAIL_CHECKING } from './emailcheckaction';
import { validateEmail } from '../../helper'

const initialState = {
  email: '',
  emailError: '',
  emailValidationPassed: false,
  isLoading: false,
};

const emailcheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    
    case EMAIL_CHECKING:
      return validateEmail(state)
      
    default:
      return state;
  }
};

export default emailcheckReducer;
