import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Div, Text } from 'atomize';

import wooden_hands from '../../../assets/images/favicon/wooden_hands.png';

import './WebApp.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

export default function Content() {
  const email = useSelector((state: RootState) => state.login.email);

  const { t } = useI18next();
  const Finik_map = t('Finik Map');
  const webapp_finik_map_p = t('WebApp_Finik_Map_p');

  return (
    <Div>
      {!email && (
        <Div
          className="finik_map_hero_div"
          data-testid="content-webapp-finikmap"
          w="335px"
          h="576px"
          border="1px solid"
          borderColor="#454545"
          rounded="10px"
          d="flex"
          flexDir="column"
          pos="relative"
          zIndex="1"
        >
          <Div
            className="webapp_finik_map"
            m="24px auto 0 16px"
            w="Hug (262px)"
            h="Hug (194px)"
            style={{
              color: '#FFFFFF',
            }}
          >
            <Text
              tag="h1"
              w="201px"
              h="78px"
              m={{ b: '16px' }}
              style={{
                fontSize: '40px',
                fontWeight: '500',
                lineHeight: '38.6px',
              }}
            >
              {Finik_map}
            </Text>
            <Text
              tag="p"
              w="262px"
              h="100px"
              style={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '19.6px',
              }}
            >
              {webapp_finik_map_p}
            </Text>
          </Div>
          <Div
            className="wooden_hands_WebApp"
            w="auto"
            h="567px"
            bg={`url(${wooden_hands})`}
            style={{
              backgroundPosition: '80% 45%',
              backgroundSize: '555px 555px',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </Div>
      )}
    </Div>
  );
}
