/* Global dependencies */
import React, { useState, useEffect } from 'react';
import { Div, Text, Input } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useSelector, useDispatch } from 'react-redux';
/* Local dependencies */
import Checking from '../sign_in/SignInComponents/Checking';
import NextButton from '../sign_in/SignInComponents/NextButton';
import { PageStages } from '../../Main/WebApp';
import { setEmail, emailChecking } from '../password_reset/redux/emailcheckaction';
import { setCurrentPage } from '../../Main/redux/currentPage_action';
import { RootState } from '../../../../redux/store';
/* Images */
import mail from '../../../../assets/images/mail.png';
/* Style */
import '../sign_in/SignIn.scss';

const EnterEmailForm = () => {
  const { t } = useI18next();
  const translater = {
    PasswordResetInputEmail: t('PasswordResetInputEmail'),
    Checking: t('Checking'),
    EnterYourEmail: t('EnterYourEmail'),
    EnterPassword: t('EnterPassword'),
    WeJustNeedYourEmail: t('WeJustNeedYourEmail'),
    Next: t('Next'),
  };

  const [isLoading, setIsLoading] = useState(false);
  const { email, emailError, emailValidationPassed } = useSelector((state: RootState) => state.signup.emailPassword);

  const dispatch = useDispatch();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEmail(e.target.value));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(emailChecking());
  };

  const onNextClick = () => {
    dispatch(setCurrentPage(PageStages.REGISTRATION_PASSWORD_PAGE));
  };

  useEffect(() => {
    if (emailValidationPassed) {
      setIsLoading(true);
      onNextClick();
    }
  }, [emailValidationPassed]);

  return (
    <form onSubmit={handleSubmit} data-testid="RegistrationEnterEmailFormTestID">
      {isLoading ? (
        <Checking />
      ) : (
        <Div
          data-testid="EnterEmailForm_Main_Div_TestID"
          w="343px"
          h="278px"
          d="flex"
          flexDir="column"
          justify="space-between"
        >
          <Div>
            <Text
              m="16px auto"
              textAlign="left"
              style={{
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '16.94px',
              }}
            >
              {translater.WeJustNeedYourEmail}
            </Text>
            <Div d="flex">
              <img
                src={mail}
                alt="#"
                style={{
                  position: 'absolute',
                  left: '4%',
                  top: '49%',
                  zIndex: '1',
                }}
              />
              <Input
                w="343px"
                h="52px"
                p={{ l: '45px' }}
                border="1.5px solid"
                borderColor="#3A3A3A"
                bordered="10px"
                bg="#222222"
                type="email"
                value={email}
                onChange={handleEmailChange}
                style={{
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '18.2px',
                  color: '#FFFFFF',
                }}
                placeholder={translater.EnterYourEmail}
              />
            </Div>
            {emailError && (
              <Div
                m={{ b: '-20px' }}
                textAlign="right"
                style={{
                  color: 'red',
                  fontSize: '10px',
                }}
              >
                {t(emailError)}
              </Div>
            )}
          </Div>
          <NextButton onClick={handleSubmit}> {translater.Next} </NextButton>
        </Div>
      )}
    </form>
  );
};

export default EnterEmailForm;
