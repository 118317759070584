import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Button, Text, Image } from 'atomize';

import logo_20 from '../../../../assets/images/logo_20_green.png';

export default function FinikMapButton() {
  const { t } = useI18next();
  const Map = t('Map');

  return (
    <Button
      data-testid="finik-map-button-inheader"
      w="127px"
      h="46px"
      rounded="10px"
      border="1px solid"
      borderColor="#454545"
      bg="linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, rgba(10, 10, 10, 0.7) 100%), linear-gradient(0deg, #454545, #454545)"
      textColor="white"
      d="flex"
      justify="center"
      align="center"
      p="0 10px"
    >
      <Image src={logo_20} alt="logo20" w="20px" h="20px" />
      <Text
        textSize="20px"
        fontWeight="500"
        lineheight="19px"
        letterSpacing="0px"
        textAlign="left"
        m={{ l: '8px' }}
        tag="span"
      >
        {Map}
      </Text>
    </Button>
  );
}
