export function validateCode(code, state) {
  const newState = {
    ...state,
    codeValidationPassed: true,
  };
  if (code[0] === undefined) {
    newState.codeError = 'codeError';
    newState.codeValidationPassed = false;
  }
  if (code[0] === '') {
    newState.codeError = 'codeError';
    newState.codeValidationPassed = false;
  }
  if (code[1] === '') {
    newState.codeError = 'codeError';
    newState.codeValidationPassed = false;
  }
  if (code[2] === '') {
    newState.codeError = 'codeError';
    newState.codeValidationPassed = false;
  }
  if (code[3] === '') {
    newState.codeError = 'codeError';
    newState.codeValidationPassed = false;
  }
  if (code[4] === '') {
    newState.codeError = 'codeError';
    newState.codeValidationPassed = false;
  }
  if (code[5] === '') {
    newState.codeError = 'codeError';
    newState.codeValidationPassed = false;
  }
  return newState;
}

export function validateEmail(state) {
  const newState = {
    ...state,
    emailValidationPassed: true,
  };
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.email)) {
    newState.emailError = 'InvalidEmailAddressError';
    newState.emailValidationPassed = false;
  }
  if (!state.email) {
    newState.emailError = 'emailError';
    newState.emailValidationPassed = false;
  }
  return newState;
}

export function validatePassword(state) {
  const newState = {
    ...state,
    passwordValidationPassed: true,
  };
  if (!state.password) {
    newState.passwordError = 'passwordError';
    newState.passwordValidationPassed = false;
    return newState;
  }
  if (state.password.length < 8) {
    newState.passwordError = 'PasswordLenghtError';
    newState.passwordValidationPassed = false;
    return newState;
  }
  if (!/[-*.,:;"!?(_~)]/.test(state.password)) {
    newState.passwordError = 'PasswordSpecialCharactersError';
    newState.passwordValidationPassed = false;
    return newState;
  }
  if (!/\d/.test(state.password)) {
    newState.passwordError = 'PasswordDigitsError';
    newState.passwordValidationPassed = false;
    return newState;
  }
  if (!/[a-z]/.test(state.password)) {
    newState.passwordError = 'PasswordLowercaseError';
    newState.passwordValidationPassed = false;
    return newState;
  }
  if (!/[A-Z]/.test(state.password)) {
    newState.passwordError = 'PasswordUppercaseError';
    newState.passwordValidationPassed = false;
    return newState;
  }
  if (state.password !== state.repeatPassword) {
    newState.repeatPasswordError = 'passwordsMustBeTheSame';
    newState.passwordValidationPassed = false;
    return newState;
  }
  return newState;
}
