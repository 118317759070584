import { 
  SET_EMAIL, 
  SET_PASSWORD, 
  AUTHORIZE_USER, 
  SIGNIN_FAILURE, 
  SIGNIN_SUCCESS,
  SIGNOUT,
  SIGNOUT_FAILURE,
  SIGNOUT_SUCCESS,
 } from './authaction';

const initialState = {
  email: '',
  password: '',
  emailError: '',
  passwordError: '',
  validationPassed: false,
  isLoading: false,
  response: null,
  error: null,
  isAuthenticated: false,
  isSigningOut: false,
  signOutError: null,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case AUTHORIZE_USER:
      const newState = {
        ...state,
        validationPassed: true,
      };
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.email)) {
        newState.emailError = 'InvalidEmailAddressError';
        newState.validationPassed = false;
      }
      if (!state.email) {
        newState.emailError = 'emailError';
        newState.validationPassed = false;
      }
      if (!state.password) {
        newState.passwordError = 'passwordError';
        newState.validationPassed = false;
        return newState;
      }
      if (state.password.length < 8) {
        newState.passwordError = 'PasswordLenghtError';
        newState.validationPassed = false;
        return newState;
      }
      if (!/[-*.,:;"!?(_~)]/.test(state.password)) {
        newState.passwordError = 'PasswordSpecialCharactersError';
        newState.validationPassed = false;
        return newState;
      }
      if (!/\d/.test(state.password)) {
        newState.passwordError = 'PasswordDigitsError';
        newState.validationPassed = false;
        return newState;
      }
      if (!/[a-z]/.test(state.password)) {
        newState.passwordError = 'PasswordLowercaseError';
        newState.validationPassed = false;
        return newState;
      }
      if (!/[A-Z]/.test(state.password)) {
        newState.passwordError = 'PasswordUppercaseError';
        newState.validationPassed = false;
        return newState;
      }
      return newState;  
    
    case SIGNIN_SUCCESS:
      return {
        ...state,
          response: action.payload,
          isAuthenticated: true,
      };
    case SIGNIN_FAILURE :
      return {
        ...state,
        error: action.payload,
        emailError: 'IncorrectUsernameOrPassword',
      };

    case SIGNOUT:
      return {
        ...state,
        isSigningOut: true,
        signOutError: null
      };
    case SIGNOUT_SUCCESS:
      return {
        ...state,
        email: '',
        password: '',
        emailError: '',
        passwordError: '',
        validationPassed: false,
        isLoading: false,
        response: null,
        error: null,
        isAuthenticated: false,
        isSigningOut: false,
        signOutError: null,
      };
    case SIGNOUT_FAILURE:
      return {
        ...state,
        isSigningOut: false,
        signOutError: action.payload
      };
    default:
      return state;
    }
};

export default loginReducer;
