import React from 'react';
import { Div, Text, Icon } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';

export default function Checking() {
  const { t } = useI18next();
  const Checking = t('Checking');
    
  return (
  <Div d="flex" flexDir="column" align="center" m={{ b: '160px' }}>
    <Icon data-testid="Loading-icon-in-signin-window" name="Loading" size="24px" color="#ACF709" />
    <Text
      m={{ t: '4px' }}
      style={{
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '16.41px',
        textAlign: 'center',
        font: 'Roboto',
      }}
    >
      {Checking}...
    </Text>
  </Div>
  )
};
