/* Global dependencies */
import React, { useState, useEffect } from 'react';
import { Div, Text, Input } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useSelector, useDispatch } from 'react-redux';

/* Local dependencies */
import Checking from '../../sign_in/SignInComponents/Checking';
import NextButton from '../../sign_in/SignInComponents/NextButton';
import { PageStages } from '../../../Main/WebApp';
import { RootState } from '../../../../../redux/store';
import { setCurrentPage } from '../../../Main/redux/currentPage_action';
import { setPassword, setRepeatPassword, passwordChecking } from '../redux/passwordcheckaction';
import { confirmPasswordRequest } from '../redux/forgotPasswordAction';
/* Style */
import '../../sign_in/SignIn.scss';

const NewPasswordForm = () => {
  const { t } = useI18next();
  const translater = {
    PasswordResetInputEmail: t('PasswordResetInputEmail'),
    Checking: t('Checking'),
    EnterNewPassword: t('EnterNewPassword'),
    ICanNotRememberThePassword: t('ICanNotRememberThePassword'),
    ReenterNewPassword: t('ReenterNewPassword'),
    Next: t('Next'),
  };

  const [isLoading, setIsLoading] = useState(false);
  const { password, passwordError, repeatPassword, repeatPasswordError, passwordValidationPassed } = useSelector(
    (state: RootState) => state.new_password,
  );
  const { confirmPasswordError, confirmPasswordResponse, errorMessage } = useSelector(
    (state: RootState) => state.forgotPassword,
  );
  const { code } = useSelector(
    (state: RootState) => state.code_check,
  );
  const { email } = useSelector(
    (state: RootState) => state.password_reset,
  );
  const verificationCodeAsString = code.join('');

  const dispatch = useDispatch();

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPassword(e.target.value));
  };

  const handleRepeatPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setRepeatPassword(e.target.value));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(passwordChecking());
    dispatch(confirmPasswordRequest(email, verificationCodeAsString, password));
  };

  const onNextClick = () => {
    dispatch(setCurrentPage(PageStages.SIGNIN_PAGE));
  };

  useEffect(() => {
    if (passwordValidationPassed && confirmPasswordResponse !== null) {
      setIsLoading(true);
      onNextClick();
    }
  }, [ passwordValidationPassed, confirmPasswordResponse ]);

  return (
    <form onSubmit={handleSubmit} data-testid="NewPasswordForm_TestID">
      {isLoading ? (
        <Checking />
      ) : (
        <Div data-testid="LoginFormTestID" w="343px" h="278px" d="flex" flexDir="column" justify="space-between">
          <Div>
            <Text
              m="5px auto 16px auto"
              textAlign="left"
              style={{
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '16.94px',
              }}
            >
              {translater.EnterNewPassword}
            </Text>
            <Input
              w="343px"
              h="52px"
              border="1.5px solid"
              borderColor="#3A3A3A"
              bordered="10px"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              style={{
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '18.2px',
                color: '#FFFFFF',
                background: '#222222',
              }}
              placeholder="********"
            />
            {passwordError && (
              <Div
                m={{ b: '-20px' }}
                textAlign="right"
                style={{
                  color: 'red',
                  fontSize: '10px',
                }}
              >
                {t(passwordError)}
              </Div>
            )}
          </Div>
          <Div>
            <Text
              m="-5px auto 16px auto"
              textAlign="left"
              style={{
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '16.94px',
              }}
            >
              {translater.ReenterNewPassword}
            </Text>
            <Input
              w="343px"
              h="52px"
              border="1.5px solid"
              borderColor="#3A3A3A"
              bordered="10px"
              type="password"
              value={repeatPassword}
              onChange={handleRepeatPasswordChange}
              style={{
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '18.2px',
                color: '#FFFFFF',
                background: '#222222',
              }}
              placeholder="********"
            />
            {repeatPasswordError && (
              <Div
                m={{ b: '-20px' }}
                textAlign="right"
                style={{
                  color: 'red',
                  fontSize: '10px',
                }}
              >
                {t(repeatPasswordError)}
              </Div>
            )}
            {errorMessage && (
              <Div
                m={{ b: '-20px' }}
                textAlign="right"
                style={{
                  color: 'red',
                  fontSize: '10px',
                }}
              >
                {t(errorMessage)}
              </Div>
            )}
          </Div>
          <NextButton onClick={handleSubmit}>{translater.Next}</NextButton>
        </Div>
      )}
    </form>
  );
};

export default NewPasswordForm;
