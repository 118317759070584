/* Global dependencies */
import { Observable } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';
import CognitoClient from '@mancho.devs/cognito';

/* Local dependencies */
import {
  signUpSucceeded,
  signUpFailed,
  LoginAction,
  LoginActionTypes,
  confirmCodeFailure,
  confirmCodeSuccess,
} from './actions';

const cognitoClient = new CognitoClient({
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
});

export function signUpEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.SIGNUP),
    switchMap(({ payload: { email, password } }: any) =>
      cognitoClient.signUp(email, password).then(signUpSucceeded).catch(signUpFailed),
    ),
  );
}

export function signUpConfirmCodeEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.CONFIRM_CODE_REQUEST),
    switchMap(({ payload: { email, code } }: any) =>
      cognitoClient.signUpConfirmCode(email, code).then(confirmCodeSuccess).catch(confirmCodeFailure),
    ),
  );
}
