/* External Dependencies */
import React from 'react';
import { Div, Button, Text, Image } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';

/* Local Dependencies */
import NextButton from './SignInComponents/NextButton';
import { PageStages } from '../../Main/WebApp';
import { setCurrentPage } from '../../Main/redux/currentPage_action';
/* Images */
import Group9314 from '../../../../assets/images/Group9314.png';

/* Style */
import './SignIn.scss';
import { useDispatch } from 'react-redux';

export default function SigninOrRegistrWindow() {
  const dispatch = useDispatch();
  const onSign_inButtonClick = () => {
    dispatch(setCurrentPage(PageStages.SIGNIN_PAGE));
  };
  const onRegistrationButtonClick = () => {
    dispatch(setCurrentPage(PageStages.REGISTRATION_PAGE));
  };

  const { t } = useI18next();
  const translater = {
    Finik_Map: t('Finik Map'),
    Sign_in: t('Sign in'),
    Registration: t('Registration'),
    WebApp_Finik_Map_p: t('WebApp_Finik_Map_p'),
  };

  return (
    <Div data-testid="signin-or-registr-window" className="signin_window">
      <Image src={Group9314} alt="Group9314"></Image>
      <Div className="signin_window_content">
        <Div h="552px">
          <Text
            tag="h1"
            m="60px auto 16px"
            style={{
              fontSize: '40px',
              fontWeight: '500',
              lineHeight: '38.6px',
            }}
          >
            {translater.Finik_Map}
          </Text>
          <Text
            style={{
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '19.6px',
            }}
          >
            {translater.WebApp_Finik_Map_p}
          </Text>
        </Div>
        <Div h="120px" d="flex" flexDir="column" justify="space-between">
          <NextButton onClick={onSign_inButtonClick}>{translater.Sign_in}</NextButton>
          <Button
            onClick={onRegistrationButtonClick}
            data-testid="registration-button-in-SigninOrRegistrWindow"
            w="343px"
            h="52px"
            bordered="10px"
            m="16px auto 0"
            p="10px"
            gap="10px"
            style={{
              background: '#101010',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '22.4px',
              color: '#ACF709',
              textAlign: 'center',
            }}
          >
            {translater.Registration}
          </Button>
        </Div>
      </Div>
    </Div>
  );
}
