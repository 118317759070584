import React from 'react';
import { Button } from 'atomize';

export default function NextButton({ children, onClick }) {
  return (
    <Button
      onClick={onClick}
      data-testid="NextButtonInLogin"
      w="344px"
      h="52px"
      bordered="10px"
      p="10px"
      gap="10px"
      bg="#ACF709"
      type="submit"
      style={{
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '22.4px',
        color: '#000000',
      }}
    >
      {children}
    </Button>
  );
}
