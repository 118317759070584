export const SET_PASSWORD = 'SET_PASSWORD';
export const PASSWORD_CHECKING = 'PASSWORD_CHECKING';
export const SET_REPEAT_PASSWORD = 'SET_REPEAT_PASSWORD';
export const SET_LOADING = 'SET_LOADING';

export const setPassword = (password) => ({
  type: SET_PASSWORD,
  payload: password,
});

export const setRepeatPassword = (repeatPassword) => ({
    type: SET_REPEAT_PASSWORD,
    payload: repeatPassword,
  });

export const passwordChecking = () => ({
    type: PASSWORD_CHECKING,
});
