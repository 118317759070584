/* External Dependencies */
import React from 'react';
import { Div, Text } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useDispatch } from 'react-redux';

/* Local Dependencies */
/* Components */
import BackAndCancelButtons from '../sign_in/SignInComponents/BackAndCancelButtons';
import SendCodeForm from './PasswordResetComponents/SendCodeForm';
import SigninText from '../sign_in/SignInComponents/SigninText';
import { PageStages } from '../../Main/WebApp';
import { setCurrentPage } from '../../Main/redux/currentPage_action';

/* Style */
import '../sign_in/SignIn.scss';

export default function PasswordReset() {
  const dispatch = useDispatch();
  const onBackClick = () => {
    dispatch(setCurrentPage(PageStages.SIGNIN_PAGE));
  };
  const onCancelClick = () => {
    dispatch(setCurrentPage(PageStages.CONTENT_PAGE));
  };

  const { t } = useI18next();
  const translater = {
    NewPassword: t('NewPassword'),
    p_Wooden_hands: t('p_Wooden_hands'),
  };
  return (
    <Div data-testid="PasswordResetMainDivTestID" className="signin_window">
      <BackAndCancelButtons onBackClick={onBackClick} onCancelClick={onCancelClick} />
      <Div className="signin_window_content">
        <Div h="205px">
          <Text
            tag="h1"
            m="60px auto 16px"
            style={{
              fontSize: '40px',
              fontWeight: '500',
              lineHeight: '38.6px',
            }}
          >
            {translater.NewPassword}
          </Text>
          <SigninText />
        </Div>
        <SendCodeForm />
      </Div>
    </Div>
  );
}
