/* External Dependencies */
import React from 'react';
import { Div, Text } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useDispatch } from 'react-redux';
/* Local Dependencies */
/* Components */
import BackAndCancelButtons from './SignInComponents/BackAndCancelButtons';
import LoginForm from './LoginForm';
import SigninText from './SignInComponents/SigninText';
import { PageStages } from '../../Main/WebApp';
import { setCurrentPage } from '../../Main/redux/currentPage_action';
/* Style */
import './SignIn.scss';

export default function SigninWindow() {
  const dispatch = useDispatch();
  const onBackClick = () => {
    dispatch(setCurrentPage(PageStages.WELCOME_PAGE));
  };
  const onCancelClick = () => {
    dispatch(setCurrentPage(PageStages.CONTENT_PAGE));
  };

  const { t } = useI18next();
  const translater = {
    p_Wooden_hands: t('p_Wooden_hands'),
    Signin: t('Signin'),
  };
  return (
    <Div data-testid="SigninWindowTestID" className="signin_window">
      <BackAndCancelButtons onBackClick={onBackClick} onCancelClick={onCancelClick} />
      <Div className="signin_window_content">
        <Div h="175px">
          <Text
            tag="h1"
            m="60px auto 16px"
            style={{
              fontSize: '40px',
              fontWeight: '500',
              lineHeight: '38.6px',
            }}
          >
            {translater.Signin}
          </Text>
          <SigninText />
        </Div>
        <LoginForm />
      </Div>
    </Div>
  );
}
