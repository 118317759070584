/* External Dependencies */
import React from 'react';
import { Div, Text } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';

/* Local Dependencies */
/* Components */
import SigninText from '../sign_in/SignInComponents/SigninText';
import WriteCodeForm from './PasswordResetComponents/WriteCodeForm';
/* Style */
import '../sign_in/SignIn.scss';

export default function WriteCode() {
  const { t } = useI18next();
  const translater = {
    NewPassword: t('NewPassword'),
    p_Wooden_hands: t('p_Wooden_hands'),
  };
  return (
    <Div data-testid="WriteCode_TestID" className="signin_window">
      <Div className="signin_window_content">
        <Div>
          <Text
            tag="h1"
            m="60px auto 16px"
            style={{
              fontSize: '40px',
              fontWeight: '500',
              lineHeight: '38.6px',
            }}
          >
            {translater.NewPassword}
          </Text>
          <SigninText />
        </Div>
        <WriteCodeForm />
      </Div>
    </Div>
  );
}
