import { SET_PASSWORD, PASSWORD_CHECKING, SET_REPEAT_PASSWORD } from './passwordcheckaction';
import { validatePassword } from '../../helper';

const initialState = {
  password: '',
  passwordError: '',
  repeatPassword: '',
  repeatPasswordError: '',
  passwordValidationPassed: false,
  isLoading: false,
};

const passwordcheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case SET_REPEAT_PASSWORD:
      return {
        ...state,
        repeatPassword: action.payload,
      };
    case PASSWORD_CHECKING:
      return validatePassword(state)

    default:
      return state;
  }
};

export default passwordcheckReducer;
