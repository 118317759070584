import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Button, Text, Image } from 'atomize';

/* Local dependencies */
import search from '../../../../assets/images/search.svg';

export default function SearchButton() {
  const { t } = useI18next();
  const Search = t('Search');

  return (
    <Button
      data-testid="button-search-webapp"
      w="310px"
      h="46px"
      d="flex"
      justify="left"
      rounded="10px"
      border="1px solid"
      borderColor="#454545"
      bg="linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, rgba(10, 10, 10, 0.7) 100%), linear-gradient(0deg, #454545, #454545)"
    >
      <Image src={search} alt="search" w="15px" h="15px" m={{ r: '8px' }}></Image>
      <Text style={{ color: '#D9D9D9' }}>Search</Text>
    </Button>
  );
}
