/* Global dependencies */
import React, { useState, useEffect } from 'react';
import { Div, Button, Text, Input } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useSelector, useDispatch } from 'react-redux';
/* Local dependencies */
import Checking from './SignInComponents/Checking';
import { PageStages } from '../../Main/WebApp';
import { RootState } from '../../../../redux/store';
import { setCurrentPage } from '../../Main/redux/currentPage_action';
import { setEmail, setPassword, authorizeUser, signIn } from './redux/authaction';
/* Style */
import './SignIn.scss';
import mail from '../../../../assets/images/mail.png';

const LoginForm = () => {
  const { t } = useI18next();
  const translater = {
    WeJustNeedYourEmail: t('WeJustNeedYourEmail'),
    Checking: t('Checking'),
    EnterYourEmail: t('EnterYourEmail'),
    EnterPassword: t('EnterPassword'),
    ICanNotRememberThePassword: t('ICanNotRememberThePassword'),
    Next: t('Next'),
  };

  const [isLoading, setIsLoading] = useState(false);
  const { 
    email, 
    password, 
    emailError, 
    passwordError, 
    validationPassed, 
    response, 
    error 
  } = useSelector((state: RootState) => state.login);

  const dispatch = useDispatch();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEmail(e.target.value));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPassword(e.target.value));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(authorizeUser());
    dispatch(signIn(email, password));
  };
  const onNextClick = () => {
    dispatch(setCurrentPage(PageStages.CONTENT_PAGE));
  };
  const onCantRememberPasswordClick = () => {
    dispatch(setCurrentPage(PageStages.PASSWORD_RESET_PAGE));
  };

  useEffect(() => {
  if (validationPassed && error === null && response === null) {
    setIsLoading(true);
  } else {
    setIsLoading(false);
    if (response !== null) {
      setIsLoading(true);
      onNextClick();
    };      
  }
  }, [validationPassed, response, error]);

  return (
    <form onSubmit={handleSubmit} data-testid="LoginFormTestIDForm">
      {isLoading ? (
        <Checking />
      ) : (
        <Div
          data-testid="LoginFormTestID"
          w="343px"
          h="265px"
          m="0 auto 0"
          d="flex"
          flexDir="column"
          p="0"
          style={{ justify: 'space-between' }}
        >
          <Div>
            <Text
              m="0 auto 16px"
              textAlign="left"
              style={{
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '16.94px',
              }}
            >
              {translater.WeJustNeedYourEmail}
            </Text>
            <Div d="flex" zIndex="0">
              <img
                src={mail}
                alt="#"
                style={{
                  position: 'absolute',
                  left: '4%',
                  top: '48%',
                  zIndex: '1',
                }}
              />
              <Input
                w="343px"
                h="52px"
                p={{ l: '45px' }}
                border="1.5px solid"
                borderColor="#3A3A3A"
                bordered="10px"
                bg="#222222"
                type="email"
                value={email}
                onChange={handleEmailChange}
                style={{
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '18.2px',
                  color: '#FFFFFF',
                }}
                placeholder={translater.EnterYourEmail}
              />
            </Div>
            {emailError && (
              <Div
                m={{ b: '-20px' }}
                textAlign="right"
                style={{
                  color: 'red',
                  fontSize: '10px',
                }}
              >
                {t(emailError)}
              </Div>
            )}
          </Div>
          <Div>
            <Text
              m="16px auto"
              textAlign="left"
              style={{
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '16.94px',
              }}
            >
              {translater.EnterPassword}
            </Text>
            <Input
              w="343px"
              h="52px"
              border="1.5px solid"
              borderColor="#3A3A3A"
              bordered="10px"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              style={{
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '18.2px',
                color: '#FFFFFF',
                background: '#222222',
              }}
              placeholder="********"
            />
            {passwordError && (
              <Div
                m={{ b: '-20px' }}
                textAlign="right"
                style={{
                  color: 'red',
                  fontSize: '10px',
                }}
              >
                {t(passwordError)}
              </Div>
            )}
          </Div>
          <Div d="flex" h="52px" m="auto 0 0 0">
            <Text
              onClick={onCantRememberPasswordClick}
              m="auto auto auto 0"
              textAlign="left"
              style={{
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '19.6px',
                color: '#ACF709',
                cursor: 'pointer',
              }}
            >
              {translater.ICanNotRememberThePassword}
            </Text>
            <Button
              data-testid="NextButtonInLogin"
              onClick={handleSubmit}
              w="210px"
              h="52px"
              bordered="10px"
              p="10px"
              gap="10px"
              bg="#ACF709"
              type="submit"
              style={{
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '22.4px',
                color: '#000000',
              }}
            >
              {translater.Next}
            </Button>
          </Div>
        </Div>
      )}
    </form>
  );
};

export default LoginForm;
