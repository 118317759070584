import { 
  SEND_CODE_FAILURE,
  SEND_CODE_SUCCESS,
  CONFIRM_PASSWORD_SUCCESS,
  CONFIRM_PASSWORD_FAILURE,  
} from './forgotPasswordAction';

const initialState = {  
  response: null,
  error: null,
  errorMessage: '',
  confirmPasswordError: null,
  confirmPasswordResponse: null,
};

const forgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {    
    case SEND_CODE_SUCCESS:
      return {
        ...state,
          response: action.payload,
      };
    case SEND_CODE_FAILURE:
      return {
        ...state,
        error: action.payload,
        errorMessage: 'EmailNotFound',
      }; 
    
    case CONFIRM_PASSWORD_SUCCESS:
      return {
        ...state,
          confirmPasswordResponse: action.payload,
      };
    case CONFIRM_PASSWORD_FAILURE:
      return {
        ...state,
        confirmPasswordError: action.payload,
        errorMessage: 'InvalidVerificationCode',
      };
    default:
      return state;
    }
};

export default forgotPasswordReducer;
