export const SET_CODE = 'SET_CODE';
export const CODE_CHECKING = 'CODE_CHECKING';
export const SET_LOADING = 'SET_LOADING';

export const setCode = (code) => ({
  type: SET_CODE,
  payload: code,
});

export const codeChecking = () => ({
    type: CODE_CHECKING,
  });
