/* Global dependencies */
import React, { useState, useEffect, useRef } from 'react';
import { Div, Text, Input } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useSelector, useDispatch } from 'react-redux';

/* Local dependencies */
import { confirmCodeRequest } from './redux/actions';
import NextButton from '../sign_in/SignInComponents/NextButton';
import { PageStages } from '../../Main/WebApp';
import { RootState } from '../../../../redux/store';
import { setCode, codeChecking } from '../password_reset/redux/codecheckaction';
import { setCurrentPage } from '../../Main/redux/currentPage_action';
/* Style */
import '../sign_in/SignIn.scss';

function CodeInputField() {
  const length = 6;
  const { t } = useI18next();
  const translater = {
    WriteCodeText: t('WriteCodeText'),
    PasswordResetInputCode: t('PasswordResetInputCode'),
    Resendthecode: t('Resendthecode'),
    Confirm: t('Confirm'),
  };

  const dispatch = useDispatch();
  const { email } = useSelector((state: RootState) => state.signup.emailPassword);
  const { code, codeError, codeValidationPassed } = useSelector((state: RootState) => state.signup.confirmCode);

  const [number, setNumber] = useState(Array(length).fill(''));
  const inputsRef = useRef([]);

  const handleChange = (e, index) => {
    const newValue = e.target.value;
    if (/^\d$/.test(newValue)) {
      const newCode = [...number];
      newCode[index] = newValue;
      setNumber(newCode);

      if (index < length - 1) {
        inputsRef.current[index + 1].focus();
      }
      dispatch(setCode(newCode));
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain').slice(0, length);
    const newCode = pastedData.split('').map((char) => (/\d/.test(char) ? char : ''));
    setNumber(newCode);
    dispatch(setCode(newCode));
  };

  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !number[index]) {
      const newCode = [...number];
      newCode[index - 1] = '';
      setNumber(newCode);
      inputsRef.current[index - 1].focus();
    }
  };

  const verificationCodeAsString = code.join('');
  const confirmCodeGetValues = () => {
    dispatch(confirmCodeRequest(email, verificationCodeAsString));
  };

  const onNextClick = () => {
    dispatch(setCurrentPage(PageStages.SIGNIN_PAGE));
  };

  useEffect(() => {
    if (codeValidationPassed) {
      confirmCodeGetValues();
      onNextClick();
    }
  }, [codeValidationPassed]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(codeChecking());
  };

  return (
    <form onSubmit={handleSubmit} data-testid="EnterCodeForm_TestID">
      <Div
        h="auto"
        style={{
          textAlign: 'start',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '19.6px',
        }}
      >
        <Div d="flex" justify="space-around" w="343px" h="48px" m="16px auto" gap="16px">
          {Array.from({ length }).map((_, index) => (
            <Input
              w="48px"
              h="48px"
              p="12px, 8px, 12px, 8px"
              border="2px solid"
              borderColor="#3A3A3A"
              bordered="10px"
              gap="10px"
              key={index}
              ref={(el) => (inputsRef.current[index] = el)}
              type="number"
              value={number[index]}
              onChange={(e) => handleChange(e, index)}
              maxLength={1}
              onPaste={handlePaste}
              onKeyDown={(e: any) => handleBackspace(e, index)}
              style={{
                background: '#222222',
                fontSize: '32px',
                fontWeight: '500',
                lineHeight: '24px',
                textAlign: 'Center',
                color: 'white',
              }}
            />
          ))}
        </Div>
        {codeError && (
          <Text
            m="-12px 0 -24px 0"
            textAlign="right"
            style={{
              color: 'red',
              fontSize: '10px',
            }}
          >
            {t(codeError)}
          </Text>
        )}
        <Text w="100%" h="40px" m="16px auto" style={{ color: '#B2B2B2' }}>
          {translater.WriteCodeText}
        </Text>
        <Text m={{ b: '35px' }} style={{ color: '#ACF709' }}>
          {translater.Resendthecode}
        </Text>
        <NextButton onClick={handleSubmit}>{translater.Confirm}</NextButton>
      </Div>
    </form>
  );
}

function EnterCodeForm() {
  const { t } = useI18next();
  const translater = {
    PasswordResetInputCode: t('PasswordResetInputCode'),
  };

  return (
    <Div data-testid="EnterCodeForm_MainDiv_TestID" m={{ t: '16px' }}>
      <Text
        htmlFor="codeInput"
        style={{
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '16.94px',
          color: '#FFFFFF',
          textAlign: 'start',
        }}
      >
        {translater.PasswordResetInputCode}
      </Text>
      <CodeInputField />
    </Div>
  );
}

export default EnterCodeForm;
